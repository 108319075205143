import React from 'react';
import i18n from "./i18n";
import semverCompare from 'semver/functions/compare';
import semverValid from 'semver/functions/valid';
import semverCoerce from 'semver/functions/coerce';

import { formatDistanceToNowStrict } from 'date-fns'
import enLocale from 'date-fns/locale/en-US'
import zhLocale from 'date-fns/locale/zh-CN'

import goldIcon from "../images/generic-icons/M-tournament_trophy-generic-gold.png";
import silverIcon from "../images/generic-icons/M-tournament_trophy-generic-silver.png";
import bronzeIcon from "../images/generic-icons/M-tournament_trophy-generic-bronze.png";
import { messageNativeXboxRetryLogin, openUrlOnDefaultBrowser } from './game-wrapper';
import deviceInfo, { OS_TYPE_OSX, PLATFORM_TYPE_XBOX_ONE, STORE_TYPE_APPLE_IOS } from "./deviceInfo";
import * as GameWrapper from '../../assets/lib/game-wrapper';
import { addPopup, clearPopupList, closePopup } from '../../components/popup/popup.component';
import ShopPopup from "../../components/popup/shop-popup/shop-popup.component";
import whitelistedNames from '../lib/whitelistedNames';
import { navigateToLocation, ROUTES } from '../../app.router';
import { store } from '../../configureStore';
import Elastic, { LOG_LEVEL } from './elastic.lib';
import { readMyLocationData, writeMyLocationData } from './local-storage';

// Assets
import * as EpicGames from './epic-games.lib';
import { forbiddenTermsList } from './forbidden-terms';
import controlPlaystation4Bg from "../images/how-to-play/controls-playstation-ps4-bg.png";
import controlPlaystation5Bg from "../images/how-to-play/controls-playstation-ps5-bg.png";
import controlXboxBg from "../images/how-to-play/controls-xbox-bg.png";
import { handleLogoutFlow } from '../../components/login/login.actions';

const dateFnsLocale = {
    'en': enLocale,
    'zh-Hans': zhLocale,
}

/**
 * transforms date from epoch to '27th February 2021' format
 * */
export const getDateStringWithEpochWithNth = (epochTime) => {
    if (!epochTime) return '';

    const nth = function(d) {
        if (d > 3 && d < 21) return 'th';
        switch (d % 10) {
            case 1:  return "st";
            case 2:  return "nd";
            case 3:  return "rd";
            default: return "th";
        }
    }

    const dateObj = new Date(0);
    dateObj.setUTCSeconds(epochTime);
    const date = dateObj.getDate();
    const month = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"][dateObj.getMonth()];

    return `${date}${nth(date)} ${month} ${dateObj.getFullYear()}`;
}

export const getDateStringWithEpoch = (epochTime) => {
    const date = new Date(0);
    date.setUTCSeconds(epochTime);
    const options = {  day: 'numeric', month: 'long', year: 'numeric' };
    return date.toLocaleDateString(dateFnsLocale[i18n.language], options);
};

export const capitalizeFirstLetter = (sourceString) => {
    return sourceString.charAt(0).toUpperCase() + sourceString.slice(1);
};

let bodyHeight = null;
export const checkWebViewDimensions = (dimensions) => {
    if(bodyHeight === null) {
        bodyHeight = document.body.clientHeight;
    }
    return dimensions && dimensions.height<bodyHeight-20 ? 'virtualKeyboardVisible' : '';
};

export const getRandomObjectInArray = (array) => {
    if(!array || !array.length) return null;
    const randomIndex = Math.floor(Math.random() * array.length);
    return array[randomIndex];
};

export const getNavigationData = (element) => {
    const navigationData = {};

    const height =element.clientHeight;
    const width = element.clientWidth;

    navigationData.height = height;
    navigationData.width = width;

    navigationData.pointTopLeft = {
        x:element.offsetLeft,
        y:element.offsetTop
    };
    navigationData.pointBottomRight = {
        x:navigationData.pointTopLeft.x + width,
        y:navigationData.pointTopLeft.y + height,
    };

    return navigationData;
};

const fetchLocationDataFromIpApiCo = async () => {
    try {
        const data = await fetchJSON('https://ipapi.co/json/');
        if (!data || data?.error) {
            throw new Error(data?.reason || 'Unknown error.');
        } else {
            return {
                ...data,
                currency: {
                    code: data?.currency
                },
                country_code: data?.country
            };
        }
    } catch (e) {

        console.log(`IPAPICO location error: ${e?.message}`);
        return { error: e?.message || 'Unknown error' };
    }
};

const ipStackKey="552c4a783594f3c5e44f0e915519408b";
export const getUsersLocationData = () => {
    return new Promise(async (resolve, reject)=>{
        const storageData = readMyLocationData();

        if (storageData) {
            resolve(sanitizeLocationData(storageData));
            return;
        }

        let requestFailed = false;
        let responseData = null;

        await fetch(`https://api.ipstack.com/check?access_key=${ipStackKey}`).then(response => {
            return response.json().then(data => {
                if (data.success === false) {
                    requestFailed = true;
                    responseData = data;
                } else {
                    responseData = data;
                }
            }).catch(e => {
                requestFailed = true;
                responseData = e;
            });
        }).catch(e => {
            requestFailed = true;
            responseData = e;
        });

        if (requestFailed) {
            const apiCoData = await fetchLocationDataFromIpApiCo();
            if (apiCoData) {
                requestFailed = false;
                responseData = apiCoData;
            }
        }

        if (requestFailed) {
            reject(responseData);
        } else {
            writeMyLocationData(sanitizeLocationData(responseData));
            resolve(sanitizeLocationData(responseData));
        }
    });
};

/**
 * Get only fields that we really need.
 **/
function sanitizeLocationData(data) {
    try {
        return {
            country_code: data?.country_code,
            currency: {
                code: data?.currency?.code
            },
            latitude: data?.latitude,
            longitude: data?.longitude
        };
    } catch (e) {
        console.error('Failed to sanitize location data: ', e);
        return data;
    }
}

export const fetchJSON = (url) => new Promise((resolve, reject) =>
    fetch(url)
        .then((response) =>
            response.json()
        ).then((data) =>
        resolve(data)
    ).catch((e) =>
        reject(e)
    )
);

export const getRemainingTime = (startDate) => {
    const currentDate = new Date();

    var delta = Math.abs(startDate - currentDate) / 1000;

    var days = Math.floor(delta / 86400);
    delta -= days * 86400;

    var hours = Math.floor(delta / 3600) % 24;
    delta -= hours * 3600;

    var minutes = Math.floor(delta / 60) % 60;
    delta -= minutes * 60;
    
    var seconds = Math.floor(delta % 60);

    if (startDate < currentDate) {
        days = 0;
        hours = 0;
        minutes = 0;
        seconds = 0;
    }

    return {
        days,
        hours,
        minutes,
        seconds
    }
}

export const isLifeTimeSubscription = (paymentData) => {
    if (paymentData.paymentType === 'userPlan') {
        if (paymentData.paymentDue === 0) {
            {
                return true;
            }
        }
    }
    const dateObj = new Date(0);
    dateObj.setUTCSeconds(paymentData.paymentDue);
    const remainingTime = getRemainingTime(dateObj);
    if (remainingTime.days > 100 * 365)//100 Years
    {
        return true;
    }

    return false;
}

export const isFreeTrialSubscription = ({ paymentType, planType }) => {
    if (paymentType === 'userPlan' && planType === 'ONE_WEEK_FREE') {
        return true;
    }

    return false;
}

/**
 * returns a string describing the time difference ('10 minutes ago', '2 days ago')
 * localized in current language
 * 
 * @param {Number} timestamp
 * @return {string} 
 */
export const timeDifference = (timestamp) => {
    // get current language
    let language = i18n.language;

    return formatDistanceToNowStrict(
        timestamp,
        {
            addSuffix: true, // ... ago
            locale: dateFnsLocale[language]
        }
    );
}

export const getNthText = (value) => {
    var mod10 = value % 10,
        mod100 = value % 100;
    if (mod10 === 1 && mod100 !== 11) return  "st";
    if (mod10 === 2 && mod100 !== 12) return  "nd";
    if (mod10 === 3 && mod100 !== 13) return  "rd";
    return "th";
}

export const getTournamentShortCode = (tournamentId, challengeId) => {
    return "tournament_" + tournamentId + "_" + challengeId;
};

export const getBadgeIconSrc = (rank) => {
    if(rank === 1){
        return goldIcon;
    } else if (rank === 2){
        return silverIcon;
    } else if (rank === 3){
        return bronzeIcon;
    }
    return null;
};

export const getBrowserLanguage = () => {
    const navigatorLanguage = navigator.language;
    //"zh-CN" isn't valid BCP 47 language code for "Simplified Chinese". But most of browsers currently using this language code for "Simplified Chinese"
    //To keep it in standards we convert it to "zh-HANS" one which is valid language code for "Simplified Chinese" in BCP 47.
    //https://www.iana.org/assignments/language-subtag-registry/language-subtag-registry
    //https://tools.ietf.org/rfc/bcp/bcp47.txt
    if (navigatorLanguage.toLowerCase() === "zh-cn") {
        return "zh-Hans"
    }
    return navigatorLanguage;
}

export function getDigitCount(number) {
    if(!number) return 0;
    return number.toString().length;
}

var oldWindowOpen = window.open;
window.open = function (url, name, features, replace) {
  // handle window.open yourself
  console.log("window.open:"+url);
  openUrlOnDefaultBrowser(url, oldWindowOpen);
}

/**
 * TODO: Removing this now impacts too much code that would need to be retested.
 * Ideally when we refactor the front-end, this should not exist,
 * and the <ShopPopup> on mount should check itself if it's on Epic, and if yes, prevent its render and
 * open the launcher store instead.
 */
export const openShopPopupOrEpicLauncher = () => {
    if (deviceInfo.osType === OS_TYPE_OSX) {
        // Fallback for OSX that doesn't support Epic Store overlay: open directly Epic Games Launcher on 'all dlc' page
        // The last part of this url must be the exact slug of our base game on Epic Store (antstream ? antstream-arcade ? unalakleet ?), otherwise it opens a 404
        GameWrapper.openUrlOnDefaultBrowser(EpicGames.LAUNCHER_SHOP_URL);
    } else {
        addPopup(<ShopPopup />);
    }
}

export const isLeaderBoardEnabledFlagErased = (prev, next) => {
    if (!prev || !next) return false;

    return prev.isLeaderBoardEnabled && !next.isLeaderBoardEnabled;
};

const isEpicUser = (store) => {
    return store
        && store.app
        && store.app.loggedUser
        && store.app.loggedUser.userName
        && store.app.loggedUser.userName.includes('epic_games_');
};
const freeTierV2Regions = [
    "CA" // "Canada"
];
export const isFreeTierV2FeatureFlagEnabled = (store) => {
    if (isEpicUser(store)) return false;
    if (isXboxUser(store)) return false;
    if (deviceInfo.isPlayStationPlatform()) return false;

    return freeTierV2Regions.includes(store?.app?.registrationCountry)
        || (store?.app?.freemiumV2User === true);
};

export const isFreemiumV4UserNotSubscribed = (store) => {
    // if (isEpicUser(store)) return false;
    // if (isXboxUser(store)) return false;
    // if (deviceInfo.isPlayStationPlatform()) return false;

    return store?.app?.freemiumV4User === true
        && store?.app?.freemiumV4AccessAllowed !== true
        && store?.app?.paymentData?.subscription !== true
    ;
};

export const isFreemiumAppleIosUser = (store) => {
    if (deviceInfo.storeType !== STORE_TYPE_APPLE_IOS) return false;

    return store?.app?.paymentData?.subscription !== true;
};

const currencySymbols = {
    GBP: '£',
    USD: '$',
    EUR: '€'
};
export const getCurrencySymbol = (currencyCode) => {
    return currencySymbols[currencyCode]
        || currencySymbols.USD;
};

export const FREEMIUM_V2_PLANS = {
    monthly: 'monthly',
    annual: 'annual'
}
export const getFreemiumV2Price = (currencyCode, plan) => {
    const currency = getCurrencySymbol(currencyCode);

    if (plan === FREEMIUM_V2_PLANS.monthly) return currency + '3.99';
    if (plan === FREEMIUM_V2_PLANS.annual) return currency + '39.99';

    return '';
};

export const SUBSCRIPTIONS_POPUP_DISPLAY_COUNT_MAX = 2;

export const isXboxUser = (store) => {
    const isXboxPlatform = deviceInfo.platformType === PLATFORM_TYPE_XBOX_ONE;
    const xboxUser = store
        && store.app
        && store.app.loggedUser
        && store.app.loggedUser.isXboxUser;

    return xboxUser || isXboxPlatform;
};
export const isXboxCrossplayEnabled = (store) => {
    return store
        && store.app
        && store.app.loggedUser
        && store.app.loggedUser.isXboxCrossplayEnabled;
};

/**
 * mask for bad words - if bad word found replace all name with ***
 * @param name {string}
 **/
export const withBadWordMask = (name) => {
    if (!name) return '';

    if (whitelistedNames[name]) return name;

    const lowercased = name.toLowerCase();
    const foundBadWord = forbiddenTermsList.some(badWord => {
        return lowercased.includes(badWord.toLowerCase());
    });

    if (foundBadWord) {
        return Array(name.length).fill('*').join('');
    }

    return name;
};

/**
 * this function shows debug popup with error text
 * used for debugging only on DEV environment
 **/
export function showDebugNotification(msg) {
    if (window.config.REACT_APP_ENV !== 'dev') return;

    const debugPopupId = "__debug-notifications-container";
    const alreadyExists = document.getElementById(debugPopupId);
    if (alreadyExists) {
        alreadyExists.remove();
    }

    const elemDiv = document.createElement('div');
    elemDiv.id = debugPopupId;
    elemDiv.style.cssText = 'border-radius:2em;border:thin solid white;padding:1em;position:absolute;width:24em;height:12em;opacity:0.8;z-index:9999;background:#000;top:0;left:33%';
    elemDiv.innerHTML = `<h3 style="text-align: center;">DEBUG MESSAGE</h3><p>${msg}</p>`
    document.body.appendChild(elemDiv);

    setTimeout(() => {
        const created = document.getElementById(debugPopupId);
        if (created) { created.remove(); }
    }, 7000)
}

/**
 * this function decides if Daily challenges feature is enabled
 **/
export function isFeatureDailyChallengesEnabled() {
    if (window.config.REACT_APP_FEATURE_DAILY_CHALLENGES_ENABLED === 'true') return true;

    const allowedEnvs = ['dev', 'stg', 'content'];

    return allowedEnvs.some(env => env === window.config.REACT_APP_ENV); 
}

/**
 * this function decides if Controller Mapping feature is enabled
 **/
export function isFeatureControllerMappingEnabled() {
    if (window.config.REACT_APP_FEATURE_CONTROLLER_MAPPING_ENABLED === 'true') return true;

    const allowedEnvs = ['dev', 'stg', 'content'];

    return allowedEnvs.some(env => env === window.config.REACT_APP_ENV);
}

export const splitArrayByChunks = (inputArray, perChunk) => {
    return inputArray.reduce((resultArray, item, index) => {
        const chunkIndex = Math.floor(index/perChunk)

        if(!resultArray[chunkIndex]) {
            resultArray[chunkIndex] = [];
        }

        resultArray[chunkIndex].push(item);

        return resultArray;
    }, []);
};

export function applyMapping(asvi_actions, mapping, fieldToRemove, fieldToAdd) {
    const gMapping = mapping?.gamepad?.mappings;
    if (!gMapping) return asvi_actions;
    const asvi = asvi_actions;
    if (!asvi) return asvi_actions;

    const asviCopy = JSON.parse(JSON.stringify(asvi_actions));
    Object.keys(asviCopy).forEach(key => {
        const gKeyId = asviCopy[key]?.gamepad?.[0];
        if (!gKeyId) return;
        if (gMapping[gKeyId] && gMapping[gKeyId] !== gKeyId) {
            asviCopy[key].gamepad = asviCopy[key].gamepad.map(keyId => {
                if (keyId === gKeyId) return gMapping[gKeyId];
                return keyId;
            });
        }

        // remove key if not swap
        if (gKeyId === fieldToRemove) {
            asviCopy[key].gamepad = asviCopy[key].gamepad.map(keyId => {
                if (keyId === fieldToRemove) return fieldToAdd;
                return keyId;
            });
        }
    });

    Object.keys(asviCopy).forEach(key => {
        const gKeyId = asviCopy[key]?.gamepad?.[1];
        if (!gKeyId) return;
        if (gMapping[gKeyId] && gMapping[gKeyId] !== gKeyId) {
            asviCopy[key].gamepad = asviCopy[key].gamepad.map(keyId => {
                if (keyId === gKeyId) return gMapping[gKeyId];
                return keyId;
            });
        }

        // remove key if not swap
        if (gKeyId === fieldToRemove) {
            asviCopy[key].gamepad = asviCopy[key].gamepad.map(keyId => {
                if (keyId === fieldToRemove) return fieldToAdd;
                return keyId;
            });
        }
    });

    return asviCopy;
}

/**
 * checks if we need to delay popups on this page (wait until user goes to another page)
 **/
export const isDelayedPage = (state) => {
    const currentPath = state.routing.currentRoute.path;
    const delayedPages = [ // pages where we should not display the pop-up
        ROUTES.SIGNUP_AVATAR.path,
        ROUTES.SIGNUP_AUTHENTICATION.path,
        ROUTES.SIGNUP_COMPLETION.path
    ];

    return delayedPages.includes(currentPath);
};

/**
 * Swaps keys with values of an object
 * example: {"Gamepad_A": "Gamepad_B"} to {"Gamepad_B": "Gamepad_A"}
 */
export function swapObjectKeyValues(obj) {
    if (!obj) return;

    const ret = {};
    Object.keys(obj).forEach(key => {
        ret[obj[key]] = key;
    });
    return ret;
}

/**
 * Checks if versionA greater than or equal versionB
 * If the version string is not valid - coerce a string into SemVer if possible. Example: semverCoerce('2.1.3329.0') => '2.1.3329'
 */
export function versionGreaterThanOrEqual(a, b) {
    let versionA = a;
    let versionB = b;
    let result = false;
    try {
        if (!semverValid(versionA)) {
            console.log('[versionGreaterThanOrEqual] versionA is not valid');
            versionA = semverCoerce(versionA);
        }
        if (!semverValid(versionB)) {
            console.log('[versionGreaterThanOrEqual] versionB is not valid');
            versionB = semverCoerce(versionB);
        }
        result = semverCompare(versionA, versionB);
    } catch (e) {
        console.error('[versionGreaterThanOrEqual] error: ', e.message);
        return null;
    }

    return result === 0 || result === 1;
}

/**
 * Checks if versionA greater than versionB
 * If the version string is not valid - coerce a string into SemVer if possible. Example: semverCoerce('2.1.3329.0') => '2.1.3329'
 */
export function versionGreaterThan(a, b) {
    let versionA = a;
    let versionB = b;
    let result = false;
    try {
        if (!semverValid(versionA)) {
            console.log('[versionGreaterThan] versionA is not valid');
            versionA = semverCoerce(versionA);
        }
        if (!semverValid(versionB)) {
            console.log('[versionGreaterThan] versionB is not valid');
            versionB = semverCoerce(versionB);
        }

        result = semverCompare(versionA, versionB);
    } catch (e) {
        console.error('[versionGreaterThan] error: ', e.message);
        return null;
    }

    return result === 1;
}

export function handleNoAuthTokenIssue(messageType) {
    Elastic.logDirect(LOG_LEVEL.ERROR, 'NO_AUTH_TOKEN ' + messageType);
}

/**
 * this is used for additional checking if user is authenticated.
 * this is helpful for a case when user is logged out
 * and trying to get back to the /settings/account page by clicking 'navigate back' browser's button, or 'backspace' in the native app.
 * In this case 'guardRouting()' method is not triggering.
 */
export function requireAuth(nextState, replace, next) {
    const isAuthenticated = store.getState().app.loggedUser;
    if (!isAuthenticated) {
        navigateToLocation(ROUTES.LOGIN);
    }
    next();
}

export function getGamepadBgImage() {
    if (deviceInfo.isPlayStationPlatform() && deviceInfo.isPlayStationPlatformPS4()) {
        return controlPlaystation4Bg;
    } else if (deviceInfo.isPlayStationPlatform() && deviceInfo.isPlayStationPlatformPS5()) {
        return controlPlaystation5Bg;
    }

    return controlXboxBg;
}

export function getGamepadButtonShortName(button) {
    if (deviceInfo.isPlayStationPlatform()) {
       return button.playStationShortName || button.shortName;
    }

    if (deviceInfo.isXboxPlatform() && button.xboxShortName) {
        return button.xboxShortName;
    }

    return button.specialName || button.shortName;
}

export function getGamepadName() {
    if (deviceInfo.isPlayStationPlatform() && deviceInfo.isPlayStationPlatformPS4()) {
        return 'playstation-ps4';
    } else if (deviceInfo.isPlayStationPlatform() && deviceInfo.isPlayStationPlatformPS5()) {
        return 'playstation-ps5';
    }

    return 'gamepad';
}

export function isEmptyObject(obj) {
    return Object.keys(obj).length === 0 && obj.constructor === Object;
}

export function getGamepadImage(howToPlayImages, preLoadFolder) {
    if (isEmptyObject(howToPlayImages)) return null;

    return preLoadFolder + (howToPlayImages['gamepad'] || howToPlayImages['control-xbox']);
}

export const replaceAchievementsToTrophies = (text, isHeader = false) => {
    if (!deviceInfo.isPlayStationPlatform()) return text;
    if (!text || typeof text !== 'string') return text;

    const replaceTo = isHeader ? 'Trophies' : 'trophies';
    return text.replace(/achievements/gi, replaceTo);
}

export function getCurrentDeviceName(defaultName) {
    if (deviceInfo.isPlayStationPlatform()) {
        if (deviceInfo.isPlayStationPlatformPS4()) {
            return 'PlayStation®4 computer entertainment system';
        }
        if (deviceInfo.isPlayStationPlatformPS5()) {
            return 'PlayStation®5 console';
        }

        return 'console';
    }

    if (defaultName) return defaultName; 

    return 'device';
}

export function consoleLogWithTimestamp(message) {
    if (window.config.REACT_APP_ENV === 'dev' || window.config.REACT_APP_ENV === 'stg') {
        console.log(new Date().toISOString() + ' ' + message);
    }
}

/**
 * Handle exception from API.
 * If token is expired - navigate to LOGIN page
 * (and request native client for new login if PS or XBOX)
 **/
let lastTimeUnauthorizedErrorMs = null;
export function handleGlobalRequestError(err) {
    if (err?.error?.data?.error === 'Unauthorized'
        || err?.error?.data?.error === 'Expired token') {
        if (lastTimeUnauthorizedErrorMs && (lastTimeUnauthorizedErrorMs + 10000) > Date.now()) {
            // do nothing, avoid to fire this multiple times in 10 seconds
        } else {
            handleLogoutFlow(store.dispatch, false);
            clearPopupList();
            closePopup();

            if (deviceInfo.isXboxPlatform() || deviceInfo.isPlayStationPlatform()) {
                // messageNativeXboxRetryLogin();
            }
            lastTimeUnauthorizedErrorMs = Date.now();
        }
    }

    throw err;
}

import React, { useEffect } from 'react';
import GenericContainer, { externalFocusableComponents } from '../../common/generic-container/generic-container.component';
import { addPopup, clearPopupList, closePopup } from '../popup.component';
import { startPaymentProcess } from '../../../app.actions';
import './subscription-comparisons-popup.component.less';
import { STORE_TEXT_FREEMIUM_V2, STORE_TEXT_FREEMIUM_V3 } from '../../../constants';
import { analyticsEvent } from '../../../assets/lib/antstreamAnalytics';
import { getCurrencyCode, getPaymentData } from '../../../app.selectors';
import { connect } from 'react-redux';
import { FREEMIUM_V2_PLANS, getFreemiumV2Price } from '../../../assets/lib/utils';
import deviceInfo from '../../../assets/lib/deviceInfo';
import { isDeviceWithNativePayments } from '../../../app.helpers';
import GenericPopup from '../generic-popup/generic-popup.component';
import { handleLogoutFlow } from '../../login/login.actions';

const navLevel = 2;
const leftHeaderTextDefault = ['Earn', 'achievements', 'with premium'];

const SubscriptionComparisonsPopup = ({ leftHeaderText, onExitClick, priceWithCurrency, customClassName, pageId, freemiumVersion = 'V2', ...props }) => {
    useEffect(() => {
        // automatically close the popup if/when subscription becomes true
        if (props.subscription === true) {
            closePopup();
        }
    }, [props.subscription]);

    function handlePremiumClick() {
        analyticsEvent({
            category: `Freemium ${freemiumVersion} Pop-Ups`,
            action: 'premium_button_clicked',
            label: `${leftHeaderText} (${pageId})`
        });

        if (freemiumVersion !== 'V3') {
            closePopup();
        }

        if (!deviceInfo.hasNativeBrowserSupport() && !isDeviceWithNativePayments()) {
            addPopup(<GenericPopup title="Sign up for Premium"
            message="To sign up for a premium account, please go to https://www.antstream.com"/>);
        } else {
            startPaymentProcess(false);
        }
    }

    function handleExitClick() {
        analyticsEvent({
            category: "Freemium V2 Pop-Ups",
            action: 'play_for_free_button_clicked',
            label: `${leftHeaderText} (${pageId})`
        });

        if (onExitClick) {
            onExitClick();
        } else {
            closePopup();
        }
    }

    function renderPrice() {
        if (freemiumVersion === 'V3') return null;
        if (!priceWithCurrency) return null;

        let content = null;
        if (priceWithCurrency.includes('.')) {
            content = (
                <span className='slot-with-coin__price-container'>
                    <span className="slot-with-coin__pounds">
                        {priceWithCurrency.split('.')[0]}.
                    </span>
                    <span className="slot-with-coin__pennies">
                        {priceWithCurrency.split('.')[1]}
                    </span>
                </span>
            );
        } else {
            content = (
                <span className="slot-with-coin__price">
                    {priceWithCurrency}
                </span>
            );
        }

        return <div className="slot-with-coin">{content}</div>;
    }

    function handleLogoutClick() {
        analyticsEvent({
            category: 'Freemium V3 Pop-Ups',
            action: 'go_home_button_clicked',
            label: `${leftHeaderText} (${pageId})`
        });

        handleLogoutFlow(props.dispatch);
        clearPopupList();
        closePopup();
    }

    function renderHeader() {
        if (freemiumVersion !== 'V3') return;
        const { priceWithCurrencyV3Monthly, priceWithCurrencyV3Yearly } = props;
        return (
            <div className='header-box'>
                <h2 className='header-main-text'>Premium Benefits</h2>
                <p className='header-price-text'>
                    {priceWithCurrencyV3Monthly} monthly or {priceWithCurrencyV3Yearly} yearly
                </p>
            </div>
        );
    }

    function getStoreText() {
        let storeText = [];

        if (freemiumVersion === 'V3') {
            storeText = STORE_TEXT_FREEMIUM_V3;
        } else {
            storeText = STORE_TEXT_FREEMIUM_V2;
        }

        if (deviceInfo.isPlayStationPlatform()) {
            storeText = storeText.map(feature => {
                if (feature === 'Achievements') {
                    return 'Trophies';
                }
                return feature;
            })
        }

        return storeText;
    }
    
    const buttons = [];
    buttons.push({
        key: freemiumVersion === 'V3' ? 'go-home-logging-out' : 'play-for-free',
        label: freemiumVersion === 'V3' ? 'Go Home' : 'Close',
        handleClick: () => {
            return freemiumVersion === 'V3'
                ? handleLogoutClick()
                : handleExitClick()
        },
        childId: 'play-for-free',
        className: `popup-button play-for-free-button`,
        navLayerValue: navLevel
    });
    buttons.push({
        key: 'premium-button',
        label: freemiumVersion === 'V3' ? 'Go Premium' : 'Premium',
        handleClick: () => { handlePremiumClick() },
        childId: 'premium-button',
        className: `popup-button premium-button`,
        navLayerValue: navLevel
    });

    const bgClass = `subscription-comparisons-popup-bg ${freemiumVersion === 'V3' ? 'subscription-comparisons-popup-bg--V3' : ''}`;
    const storeText = getStoreText();

    return (
        <GenericContainer
            className={`subscription-comparisons-popup ${customClassName || ''}`}
            showPopupBackground={true}
            focusParentId={externalFocusableComponents.BUTTON_CONTAINER}
            focusElement='premium-button'
            navLayerValue={navLevel}
            buttonContainerClass="subscription-comparisons-popup-buttons"
            buttons={buttons}
        >
            <div className={bgClass} />
            <div className="subscription-comparisons-popup-content">
                {renderHeader()}
                {renderPrice()}
                <div className="left-col">
                    <h3 className="left-col__text">
                        {leftHeaderText
                            ? leftHeaderText.map(el => <span className="left-col__text-elem" key={el}>{el}</span>)
                            : leftHeaderTextDefault.map(el => <span className="left-col__text-elem" key={el}>{el}</span>)}
                    </h3>
                </div>
                <div className="right-col">
                    <div className="premium-features">
                        {storeText.map(feature => <p key={feature}>{feature}</p>)}
                    </div>
                </div>
            </div>
        </GenericContainer>
    )
}

const mapStateToProps = (state) => {
    const paymentData = getPaymentData(state) || {};
    return {
        subscription: paymentData.subscription,
        priceWithCurrency: getFreemiumV2Price(getCurrencyCode(state), FREEMIUM_V2_PLANS.monthly),
        priceWithCurrencyV3Monthly: getFreemiumV2Price(getCurrencyCode(state), FREEMIUM_V2_PLANS.monthly),
        priceWithCurrencyV3Yearly: getFreemiumV2Price(getCurrencyCode(state), FREEMIUM_V2_PLANS.annual),
    };
};

export default connect(mapStateToProps)(SubscriptionComparisonsPopup);
